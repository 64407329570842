import React, { useContext, PropsWithChildren, MouseEvent } from "react";
import NavigationContext from "../contexts/NavigationContext";
import { useSelector } from "@xstate/react";
type SutroLinkProps = {
  to: string,
  isActive?: (url: string) => boolean,
  /**
   * 
   * @returns should return true to blocking navigation
   */
  onClick?: () => void | boolean,
  className?: string
};

const SutroLink = React.forwardRef<HTMLAnchorElement, PropsWithChildren<SutroLinkProps>>(({ to, children, isActive, onClick, className }: PropsWithChildren<SutroLinkProps>, ref) => {
  const navContext = useContext(NavigationContext);
  const { currentLocation, pendingLocation } = useSelector(navContext.navManager.navigationActor, ({ context }) => {
    return {
      currentLocation: context.currentLocation,
      pendingLocation: context.pendingLocation
    };
  });
  const active = isActive ? isActive(currentLocation) : currentLocation === to;
  const clickHandler = (e: MouseEvent) => {
    e.preventDefault();
    const result = onClick ? onClick() : null;
    if (!result && navContext.navManager.navigationActor.getSnapshot().context.currentLocation !== to && !pendingLocation) {
      navContext.navManager.requestNavigation(to);
    }
  };
  return <a ref={ref} onClick={clickHandler} href={to} className={[className, active ? 'active' : ''].filter(e => e).join(' ')}>{children}</a>;
});
export default SutroLink;