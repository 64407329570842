import { createContext } from "react";
interface LayoutContext {
  showHeader: boolean,
  setShowHeader: React.Dispatch<React.SetStateAction<boolean>>,
  gradientOn: boolean,
  setGradient: React.Dispatch<React.SetStateAction<boolean>>,
  isDetailOverlayOpen: boolean,
  setIsDetailOverlayOpen: React.Dispatch<React.SetStateAction<boolean>>,
  requestLoading: (key: string) => void,
  releaseLoading: (key: string) => void

}

const LayoutContext = createContext<LayoutContext>({
  showHeader: true,
  setShowHeader() { },
  gradientOn: false,
  setGradient() { },
  isDetailOverlayOpen: false,
  setIsDetailOverlayOpen() { },
  requestLoading(key: string) { false && key; },
  releaseLoading(key: string) { false && key; }
});
export default LayoutContext;