import { createContext } from "react";
import { ActorRefFrom, createActor } from "xstate";
import NavigationMachine from "../xstate/machines/NavigationMachine";
import { History, createBrowserHistory } from "history";
import sitePages from '../site-structure.json';
const allPagePaths = sitePages.map(v => v.path);
const pathToTitle = sitePages.reduce((m, v) => {
  if (v.title) {
    m[v.path] = v.title;
  }
  return m;
}, {} as { [id: string]: string });
const history = createBrowserHistory();
type NavigationMachineType = typeof NavigationMachine;
const navigationActor = createActor(NavigationMachine, {
  input: {
    currentLocation: '',
    pendingLocation: undefined
  }
});
export enum NavigationInterceptorResult {
  Pass,
  Queue,
  Immediate
}

export type NavigationInterceptor = (to: string) => NavigationInterceptorResult;
export class NavigationManager {
  pendingNavigationRequest: string;
  navigationActor: ActorRefFrom<NavigationMachineType>;
  history: History;
  interceptors: { [name: string]: NavigationInterceptor } = {};
  navLocks = new Set<string>();
  private _wasViewing: boolean = false;
  private _lastViewingLocation: string = '';

  constructor(navigationActor: ActorRefFrom<NavigationMachineType>, history: History) {
    this.navigationActor = navigationActor;
    this.history = history;
    this.pendingNavigationRequest = '';
    // strip trailing slash
    if (this.history.location.pathname.match(/.\/$/)) {
      this.history.replace(this.history.location.pathname.replace(/\/$/, ''));
    }
    this.navigationActor.subscribe((snapshot) => {
      console.log(JSON.stringify(snapshot.value));
      const viewing = snapshot.matches('viewing');
      if ((!this._wasViewing && viewing) ||
        // OR if we received an immediate location update (sub url of sub page)
        (viewing && this._lastViewingLocation != snapshot.context.currentLocation)
      ) {
        this._lastViewingLocation = snapshot.context.currentLocation;
        this.forceURLPush(snapshot.context.currentLocation);
        const title = pathToTitle[snapshot.context.currentLocation];
        if (title) {
          document.title = `${title} - Explore Sutro Tower`;
        } else {
          document.title = `Explore Sutro Tower`;
        }
        this.pendingNavigationRequest = '';
      }
      this._wasViewing = viewing;
    });
    this.navigationActor.start();
    this.navigationActor.send({ type: 'START', to: this.history.location.pathname.replace(/.\/$/, '') });
    this.history.listen((update) => {
      if (update.action === 'POP') {
        this.requestNavigation(update.location.pathname);
      }
    });
  }
  addLock(id: string) {
    this.navLocks.add(id);
    // console.log('add', this.navLocks);
  }
  removeLock(id: string) {

    // don't do anything if locks already empty
    if (!this.navLocks.size) {
      return;
    }

    this.navLocks.delete(id);
    // if locks newly empty retry last requested nav
    if (!this.navLocks.size && this.pendingNavigationRequest) {
      this.requestNavigation(this.pendingNavigationRequest);
    }
    // console.log('rm', this.navLocks);
  }

  addIntercept(key: string, intercept: NavigationInterceptor) {
    this.interceptors[key] = intercept;
  }
  removeIntercept(key: string) {
    delete this.interceptors[key];
  }
  requestNavigation(to: string): boolean {
    console.log(`request nav to: ${to}`);
    const iResults = Object.values(this.interceptors).map(v => v(to));
    const queue = iResults.some(v => v === NavigationInterceptorResult.Queue);

    if (queue || this.navLocks.size > 0) {
      this.pendingNavigationRequest = to;
      console.log('queueing nav to ', to);
      return false;
    }
    const immediate = iResults.some(v => v === NavigationInterceptorResult.Immediate);
    if (immediate) {
      this.navigationActor.send({ type: 'IMMEDIATE_CHANGE', to });
    } else {
      const fromPage = this.navigationActor.getSnapshot().context.currentLocation;
      this.navigationActor.send({ type: 'NAV_REQUEST', to });
      if (!allPagePaths.find(v => v === to)) {
        console.error(`navigating to missing page ${to}`);
        this.navigationActor.send({ type: "START_LOAD" });
        this.navigationActor.send({ type: "COMPLETE_LOAD" });
        this.navigationActor.send({ type: "START_IN_TRANSITION" });
        this.navigationActor.send({ type: "COMPLETE_IN_TRANSITION" });
      }
      if (!allPagePaths.find(v => v === fromPage)) {
        console.error(`navigating from missing page ${fromPage}`);
        this.navigationActor.send({ type: "START_OUT_TRANSITION" });
        this.navigationActor.send({ type: "COMPLETE_OUT_TRANSITION" });
      }
    }

    return true;
  }
  forceURLReplace(to: string) {
    this.history.replace(to);
  }
  forceURLPush(to: string) {
    this.history.push(to);
  }
}
const navManager = new NavigationManager(navigationActor, history);

export type NavigationContextType = {
  history: History,
  navManager: NavigationManager
};
const NavigationContext = createContext<NavigationContextType>({
  history,
  navManager
});

export default NavigationContext;